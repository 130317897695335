/** @jsxImportSource @emotion/react */
import { Trans } from "react-i18next";

import ImageWithSteps from "../components/ImageWithSteps";

import { ExternalLinks } from "../constants";

import CreateTrustlineImage1 from "../images/create-trustline-step-1.png";
import CreateTrustlineImage1_2x from "../images/create-trustline-step-1@2x.png";
import CreateTrustlineImage1_3x from "../images/create-trustline-step-1@3x.png";
import CreateTrustlineImage2 from "../images/create-trustline-step-2.png";
import CreateTrustlineImage2_2x from "../images/create-trustline-step-2@2x.png";
import CreateTrustlineImage2_3x from "../images/create-trustline-step-2@3x.png";
import CreateTrustlineImage3 from "../images/create-trustline-step-3.png";
import CreateTrustlineImage3_2x from "../images/create-trustline-step-3@2x.png";
import CreateTrustlineImage3_3x from "../images/create-trustline-step-3@3x.png";
import CreateTrustlineImage4 from "../images/create-trustline-step-4.png";
import CreateTrustlineImage4_2x from "../images/create-trustline-step-4@2x.png";
import CreateTrustlineImage4_3x from "../images/create-trustline-step-4@3x.png";

const CREATE_TRUSTLINE_STEPS = [
  {
    title: "stepsTrustline.firstTitle",
    body: "stepsTrustline.firstBody",
    imgSrc: CreateTrustlineImage1,
    imgSrcSet: `${CreateTrustlineImage1} 1x, ${CreateTrustlineImage1_2x} 2x, ${CreateTrustlineImage1_3x} 3x`,
    id: "create-trustline-step-1",
  },
  {
    title: "stepsTrustline.secondTitle",
    body: "stepsTrustline.secondBody",
    imgSrc: CreateTrustlineImage2,
    imgSrcSet: `${CreateTrustlineImage2} 1x, ${CreateTrustlineImage2_2x} 2x, ${CreateTrustlineImage2_3x} 3x`,
    id: "create-trustline-step-2",
  },
  {
    title: "stepsTrustline.thirdTitle",
    body: "stepsTrustline.thirdBody",
    imgSrc: CreateTrustlineImage3,
    imgSrcSet: `${CreateTrustlineImage3} 1x, ${CreateTrustlineImage3_2x} 2x, ${CreateTrustlineImage3_3x} 3x`,
    id: "create-trustline-step-3",
  },
  {
    title: "stepsTrustline.fourthTitle",
    body: "stepsTrustline.fourthBody",
    imgSrc: CreateTrustlineImage4,
    imgSrcSet: `${CreateTrustlineImage4} 1x, ${CreateTrustlineImage4_2x} 2x, ${CreateTrustlineImage4_3x} 3x`,
    id: "create-trustline-step-4",
  },
];

export default function CreateTrustlineSection() {
  return (
    <ImageWithSteps
      id="features-section"
      steps={CREATE_TRUSTLINE_STEPS}
      scrollContainerID="create-trustlines"
      sectionTitle="stepsTrustline.sectionTitle"
      sectionBody={
        <Trans i18nKey="stepsTrustline.sectionBody">
          To enable transfers, create a{" "}
          <a
            className="paragraph-link"
            target="_blank noreferrer"
            href={ExternalLinks.TRUSTLINE}
          >
            trustline
          </a>{" "}
          with a friend.
        </Trans>
      }
      userGuideLink={ExternalLinks.USER_GUIDE_CREATE_TRUSTLINE}
      hideMarginBottomLastStep
    />
  );
}
