/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import lozad from "lozad";
import { animateScroll } from "react-scroll";
import { useTranslation } from "react-i18next";

import CustomHelmet from "../components/custom-helmet";
import Header from "../sections/Header";
import Hero from "../sections/Hero";
import AboutSection from "../sections/AboutSection";
import CharacteristicsSection from "../sections/CharacteristicsSection";
import CreateTrustlineSection from "../sections/CreateTrustlineSection";
import SendPaymentSection from "../sections/SendPaymentSection";
import MultiHopSection from "../sections/MultiHopSection";
import SplitBillSection from "../sections/SplitBillSection";
import NetworkSection from "../sections/NetworkSection";
import EcosystemSection from "../sections/EcosystemSection";
// import TestimonialSection from "../sections/TestimonialSection";
import FeedbackSection from "../sections/FeedbackSection";
import ConnectWithUsSection from "../sections/ConnectWithUsSection";
import ContactUsSection from "../sections/ContactUsSection";
import StoreButtonsSection from "../sections/StoreButtonsSection";
import Footer from "../sections/Footer";

import Text from "../components/Text";

import withMaxWidth from "../hoc/withMaxWidth";

const observer = lozad();

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    observer.observe();
  }, []);

  return (
    <div id="page-container">
      <CustomHelmet title="Trustlines App | A payment app built on blockchain" />
      {withMaxWidth(<Header />)}
      {withMaxWidth(<Hero />)}
      {withMaxWidth(<AboutSection />)}
      {withMaxWidth(<CharacteristicsSection />)}
      {withMaxWidth(<CreateTrustlineSection />)}
      {withMaxWidth(<SendPaymentSection />)}
      {withMaxWidth(<MultiHopSection />)}
      {withMaxWidth(<SplitBillSection />)}
      {withMaxWidth(<NetworkSection />)}
      <EcosystemSection />
      {/* <TestimonialSection /> */}
      <FeedbackSection />
      <ConnectWithUsSection />
      <StoreButtonsSection />
      <ContactUsSection />
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 56,
          }}
        >
          <Text type="downloadLink" onClick={() => animateScroll.scrollToTop()}>
            {t("footer.scroll")}
          </Text>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Home;
