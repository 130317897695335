/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

import Text from "../components/Text";
import withMaxWidth from "../hoc/withMaxWidth";

import { MEDIA_QUERIES } from "../theme";

export default function ContactUsSection() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      css={{
        display: "flex",
        marginBottom: 56,
        height: 196,
        ":hover": {
          cursor: "pointer",
        },
        [MEDIA_QUERIES.s.max]: {
          marginTop: 64,
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      href={"mailto:contact@trustlines.app"}
      target="_blank noreferrer"
    >
      <BaseContactUsSection />
      <CSSTransition
        in={isHovered}
        classNames="gradient-button"
        timeout={300}
        unmountOnExit
      >
        <HoveredContactUsSection />
      </CSSTransition>
    </a>
  );
}

function BaseContactUsSection() {
  return (
    <div
      css={{
        display: "flex",
        position: "absolute",
        width: "100%",
        height: 196,
        backgroundColor: "#007AFF",
      }}
    >
      <Content />
    </div>
  );
}

function HoveredContactUsSection() {
  return (
    <div
      css={{
        display: "flex",
        position: "absolute",
        width: "100%",
        height: 196,
        backgroundImage:
          "linear-gradient(131.04deg, #F1B3C2 -30.65%, #7657ED 12.4%, #007AFF 71.98%, #81BCFD 114.38%)",
      }}
    >
      <Content />
    </div>
  );
}

function Content() {
  const { t } = useTranslation();

  return withMaxWidth(
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        [MEDIA_QUERIES.l.max]: {
          padding: 32,
        },
        [MEDIA_QUERIES.s.max]: {
          flexDirection: "column",
          paddingLeft: 16,
          paddingRight: 16,
        },
      }}
    >
      <Text type="bannerTitle">{t("contact.title")}</Text>
      <Text type="bannerBody">contact@trustlines.app</Text>
    </div>
  );
}
