/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { MEDIA_QUERIES } from "../theme";
import { ExternalLinks } from "../constants";

import PlayStoreIcon from "../images/play-store.svg";
import AppStoreIcon from "../images/app-store.svg";
import DownloadIcon from "../images/download.svg";
import DownloadBlueIcon from "../images/download-blue.svg";

const typeToIconsAndColor = {
  playStore: {
    getIcon: () => PlayStoreIcon,
    getDownloadIcon: () => DownloadIcon,
    color: "#7657ED",
    label: "Play Store",
    link: ExternalLinks.PLAY_STORE,
  },
  appStore: {
    getIcon: () => AppStoreIcon,
    getDownloadIcon: () => DownloadBlueIcon,
    color: "#007AFF",
    label: "App Store",
    link: ExternalLinks.APP_STORE,
  },
};

const filterStyle = {
  filter: "brightness(80%)",
};

export default function StoreButton({ type, customCSS }) {
  const [isHovered, setIsHovered] = useState(false);

  const { color, getIcon, getDownloadIcon, label, link } = typeToIconsAndColor[
    type
  ];

  const hoveredStyle = isHovered ? filterStyle : {};

  return (
    <a
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      css={[
        {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          transition: "200ms ease-in",
          zIndex: 10,
          ":hover": {
            cursor: "pointer",
          },
        },
        hoveredStyle,
        customCSS,
      ]}
      href={link}
      target="_blank noreferrer"
    >
      <img
        src={getIcon()}
        alt="store button"
        css={{
          marginRight: 8,
          height: 60,
          width: 60,
          [MEDIA_QUERIES.m.max]: {
            height: 40,
            width: 40,
          },
        }}
      />
      <div
        css={[
          {
            fontWeight: 500,
            fontStyle: "normal",
            color,
            fontSize: 19,
            marginRight: 8,
            whiteSpace: "nowrap",
            [MEDIA_QUERIES.m.max]: {
              fontSize: 16,
            },
          },
        ]}
      >
        {label}
      </div>
      <img
        src={getDownloadIcon()}
        alt="download icon"
        css={{
          height: 24,
          width: 24,
        }}
      />
    </a>
  );
}
