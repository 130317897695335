/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import changelog from "../changelog";

function Changelog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      css={{
        padding: 30,
        textAlign: "justify",
      }}
    >
      <ReactMarkdown source={changelog} />
    </div>
  );
}

export default Changelog;
