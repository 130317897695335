export const PRIVACY_POLICY_PART_2 = `
In addition to the data mentioned, Matomo also uses so-called “cookies” (see below).

Cookies
-----------

We use cookies on our website to make our offer user-friendly (Matomo, see above). Cookies are small files that your browser automatically creates and that are stored on your end device (laptop, tablet, smartphone, etc.) when you visit our website. The cookies remain stored until you delete them. This enables us to recognize your browser the next time you visit.

If you do not want this, you can set up your browser so that it informs you about the setting of cookies and allows them in individual cases. However, we would like to point out that deactivation means that you may not be able to use all functions of our website.


Changes
-----------

We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

Contact us
-----------

For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at contact@trustlines.app.
`;

export default PRIVACY_POLICY_PART_2;
