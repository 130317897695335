/** @jsxImportSource @emotion/react */
import { MEDIA_QUERIES } from "../theme";

const textTypes = {
  headerLink: {
    color: "#6C6C73",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    ":hover": {
      cursor: "pointer",
      color: "#007AFF",
      transition: "color 200ms ease-in",
    },
  },
  languageLabel: {
    color: "#6C6C73",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
  },
  hero: {
    color: "#333333",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 52,
    lineHeight: 1.2,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 48,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 32,
    },
  },
  subhero: {
    color: "#3e3e48",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 19.75,
    lineHeight: 1.6,
    [MEDIA_QUERIES.l.max]: {
      fontSize: 18,
    },
    [MEDIA_QUERIES.s.max]: {
      textAlign: "center",
      fontSize: 16,
      lineHeight: 1.5,
    },
    [MEDIA_QUERIES.xs.max]: {
      lineHeight: 1.4,
    },
  },
  sectionTitle: {
    color: "#12121F",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 32,
    lineHeight: 1.4,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 24,
      lineHeight: 1.4,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 21,
      lineHeight: 1.4,
    },
  },
  stepTitle: {
    color: "#12121F",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 28,
    lineHeight: 1.4,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 20,
      lineHeight: 1.4,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 16,
      lineHeight: 1.4,
    },
  },
  sectionBody: {
    color: "#6C6C73",
    fontStyle: "normal",
    fontSize: 19,
    lineHeight: 1.6,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 16,
      lineHeight: 1.6,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 14,
      lineHeight: 1.6,
    },
  },
  downloadLink: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 19,
    lineHeight: 1.6,
    color: "#AAAABB",
    textDecoration: "underline",
    ":hover": {
      cursor: "pointer",
      color: "#007AFF",
      transition: "color 200ms ease-in",
    },
    [MEDIA_QUERIES.m.max]: {
      fontSize: 18,
      lineHeight: 1.5,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 16,
      lineHeight: 1.4,
    },
  },
  bannerTitle: {
    color: "#ffffff",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 50,
    lineHeight: 1.2,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 48,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 32,
    },
  },
  bannerBody: {
    color: "#ffffff",
    fontStyle: "normal",
    fontSize: 30,
    lineHeight: 1.4,
    [MEDIA_QUERIES.m.max]: {
      fontSize: 24,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 16,
    },
  },
  footerLink: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 19,
    lineHeight: 1.2,
    color: "#6C6C73",
    width: 240,
    textAlign: "center",
    ":hover": {
      cursor: "pointer",
      color: "#007AFF",
      transition: "color 200ms ease-in",
    },
    [MEDIA_QUERIES.m.max]: {
      fontSize: 14,
    },
    [MEDIA_QUERIES.xs.max]: {
      fontSize: 12,
    },
  },
  info: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: 1.6,
    color: "#6C6C73",
    [MEDIA_QUERIES.s.max]: {
      fontSize: 12,
    },
  },
};

export default function Text({
  type,
  label,
  children,
  customCSS,
  isAnchor,
  ...restProps
}) {
  if (isAnchor) {
    return (
      <a css={[textTypes[type], customCSS]} {...restProps}>
        {label || children}
      </a>
    );
  }

  return (
    <div css={[textTypes[type], customCSS]} {...restProps}>
      {label || children}
    </div>
  );
}
