/** @jsxImportSource @emotion/react */
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import ImprintAndPrivacy from "./pages/ImprintAndPrivacy";
import Changelog from "./pages/Changelog";
import { PageSkeleton } from "./pages/PageSkeleton";
import { usePageViews } from "./stats";

function App() {
  usePageViews();
  return (
    <Switch>
      <Route path="/changelog_raw">
        <Changelog />
      </Route>

      <Route path="/changelog">
        <PageSkeleton withSectionLinks={false}>
          <Changelog />
        </PageSkeleton>
      </Route>

      <Route path="/imprint-privacy-policy">
        <PageSkeleton withSectionLinks={false}>
          <ImprintAndPrivacy />
        </PageSkeleton>
      </Route>

      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default App;
