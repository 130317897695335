/** @jsxImportSource @emotion/react */
import RightHero from "../components/RightHero";
import LeftHero from "../components/LeftHero";
import DownloadAPK from "../components/DownloadAPK";

import useDeviceUserAgent from "../hooks/useDeviceUserAgent";
import { BASE_FLEX_STYLE } from "../theme";

const maxHeightStyle = {
  maxHeight: 900,
};

export default function Hero() {
  const { isMobile } = useDeviceUserAgent();

  const maxHeight = isMobile ? {} : maxHeightStyle;

  return (
    <>
      <div css={[BASE_FLEX_STYLE, { height: "85vh" }, maxHeight]}>
        <LeftHero />
        <RightHero />
      </div>
      {isMobile ? null : (
        <div css={{ height: 102 }}>
          <DownloadAPK />
        </div>
      )}
    </>
  );
}
