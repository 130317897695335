export const CHANGELOG = `
Changelog
==============
Behold! The almighty changelog!


# Version 1.26.3

## Bug fixes
- Fix a startup crash on some android devices
- Allow users to remove a no longer indexed currency network


# Version 1.26.2

## Miscellaneous
- Make the Gnosis migration a little more user-friendly

# Version 1.26.1

## Bug fixes
- Fix startup crash on Android 12


# Version 1.26.0

## Miscellaneous
- The app is now connected to Gnosis Chain (The Trustlines blockchain has been sunset)
- All Trustline identities are now a Gnosis Safe
- All Trustline balances on TLBC have been frozen
- The Trustline balances on TLBC have been migrated to Gnosis Chain


# Version 1.25.1

## Miscellaneous
- update translations


# Version 1.25.0

## Miscellaneous
- update internal dependencies

# Version 1.24.0

## Features
- A new “Earnings” screen that shows earnings generated from mediated transfers or interest on a trustline


# Version 1.23.0

## Bug fixes
- Proposing a change to already accepted Trustline was not being sent to the counterparty
- Push notifications for payment requests were not shown
- Reset app was not properly resetting the database on the device

# Version 1.22.0

## Bug fixes
- The onboarding flow now navigates correctly when biometric authentication is turned on

# Version 1.21.0

## Bug fixes
- Prevent the creation of trustlines with credit limits set to 0
- Improvements to UI when a counterparty name is missing 
- Improved scanning of a deep link when not connected to the internet 
- Fix to navigation after accepting a trustline in the contact screen
- Fixed an incorrect available value being displayed on the currency selection screen

# Version 1.20.2

## Bug fixes
- Dynamic trustline request link with balance was not opening the correct screen
- On-boarding new users with balance was not opening the correct screen.

# Version 1.20.1

## Bug fixes
- Speed improvements
- Following a trustline request link was not working in some situations
- Close the QR Code scanner screen when scanning a trustline request

# Version 1.20.0

## Features
- Users can open a new trustline with a pre-defined balance

## Bug fixes
- Fixed non-working push notifications
- Fixed flickering payment request event screen

# Version 1.19.0

## Features
- Newly styled navigation throughout the app
- Added a link to the user guide

## Bug fixes
- Fixed several error messages for the offline states of the app
- Fix for when the app would crash after sending a bill splitting request
- Fixed crashing when navigating to the More Info screen
- Fixed an issue with re-adding deleted contacts
- Fixed an issue with tapping the send button multiple times while sending a payment request
- You can now tap anywhere on the dashboard screen to close the opened currency picker list

# Version 1.18.0

## Features
- Added support for the Burmese language
- Added link to CrowdIn for translation contributions

# Version 1.17.1

## Features
- Now showing events that are in progress to avoid blocking the user while in the transaction screen
- More user-friendly colors in events with a different status
- Some updates to the translations

## Bug fixes
- Fixed issues with notifications in app-lock mode
- Fixed a performance issue in the contact screen

# Version 1.17.0

## Features
- A more user-friendly passphrase verification screen
- Fees are now displayed in total on the payment review screen for easier viewing
- Replaced the network slider in the contact details screen with a new currency picker

## Bug fixes
- Fixed issues related to refreshing the trustline balance amounts in the contact screen
- Filters no longer reset when navigating back after making a payment

# Version 1.16.0

## Features
- Unlock your account with device biometrics 
- Added the Kenyan Shilling currency network

# Version 1.15.0

## Features
- Improved User Interface when dealing with Hours currency network
- Added filtering by event type

## Bug fixes
- The "New Trustline" button now takes into account the currently active network
- Switching between accounts was showing wrong balances


# Version 1.14.2

## Bug fixes
- Creating a new trustline was showing frozen networks
- Upgrade screen was missing button to play store listing
- No suggested limits for the EUR network were displayed

# Version 1.14.1

## Bug fixes
- Creating a new trustline was not properly activating the currency network
- Fixed wrong trustlines count after activating a currency network

# Version 1.14.0

## Features
- New currency picker on Overview Screen
- Filter events by status

## Bug fixes
- Dynamic links not working on iOS
- Some recent activities were missing on the Contacts Screen

## Miscellaneous
- Prepare app for upcoming currency networks update

# Version 1.13.0
 
## Bug fixes
- Fix random crashes on some older devices 

## Features
- Backup your wallet data in the cloud. Supported now are GDrive and iCloud.

## Miscellaneous
- Prepare app for upcoming currency network update  
- Improve UI on some screens

# Version 1.12.0

## Bug fixes
- In some cases, the dynamic links were not correctly launching the app. This has been made to work more consistently
- Filtering of events on the updates screen has been made faster

# Version 1.11.1

## Bug fixes
- Fix deep linking issues on iOS
- Fix crashing issue when sending TLC within the app
- Fix notification handling on iOS
- Fix crashing issue when importing legacy payment requests

# Version 1.11.0

## Features
- Design improvements to events and the events list
- The app is now multilingual with options for German and Spanish

## Bug fixes
- Fix the wrong position of the "no internet connection" bar
- Fixes to UI glitches

# Version 1.10.0

## Features

- Adds the ability to send bill splitting requests to multiple recipients

# Version 1.9.0

## Features

- The relay server can now be changed in the login screen
- Improved the invitation process for non-Trustlines users 
- Added the ability to create a trustline request without pre-selecting a contact

## Bug fixes

- Minor UI issues

# Version 1.8.0

## Features

- Support for enriched push notifications
- Include activated currencies in backup data
- Better formatting for numbers that are very large or small
- Automatically display the changelog after every update
- Changes to button styles making them consistent throughout the app

## Bug fixes

- Small UI improvements (wording and colors)
- Pressing back in payment request flow navigated to wrong screen
- "DB or disk full error" on android

# Version 1.7.1

## Bug fixes
- Remote party name scanned from a QR-code was not shown in the app after payment

# Version 1.7.0

## Features
- New send/receive payment flow
- Display more information for payment events (hops, rippled over etc.)

# Version 1.6.0

## Features
- New account creation / onboarding flow
- New trustline creation flow
- Include a message when sending a payment to another user
- Decline a trustline request
- Sticky header warning when the user hasn't backed up their passphrase
- Include non-blockchain events in the backup data (payment request, payment subjects, etc.)
- Android - Create smaller bundle sizes

# Version 1.5.4

## Bug-fixes:
- Fix some minor UI glitches
- Improved performance on contact screen for users that have a lot of contacts and transfers
- Couldn't paste text in some input elements

## Features:
- Mark payment request as paid
- Tutorial screen is now available under the Account tab
- Switch relay server screens have been simplified
- Scanning a payment request will now also display the name of the user encoded in the QR code
- Ability to save a contact when requesting a payment
- Senders of Trustline requests can now also cancel them

# Version 1.5.3
This is our first release after EthCC. Thank you all for participating in our first public beta test on mainnet and for
helping us polish the user experience!

## Fixes & Updates
- **Couldn't scroll on the overview screen**
- **Notifications were incorrectly marked as read**
- **Couldn't send a payment under certain circumstances**
- **Overview screen was not properly loading when the TLC was turned on**
- **Notification checkmarks were misleading in some situations**
- **Latest contact activity was not auto-updating**
- **Currencies in the slider are now sorted alphabetically**
- **Internal code optimisations**
`;

export default CHANGELOG;
