/** @jsxImportSource @emotion/react */
import { Link as ScrollLink } from "react-scroll";

import Text from "./Text";

export default function HeaderLink({ to = "", label = "" }) {
  return (
    <ScrollLink to={to} smooth={true} offset={-70} duration={500}>
      <Text type="headerLink" label={label} />
    </ScrollLink>
  );
}
