/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { MEDIA_QUERIES } from "../theme";

const buttonStyle = {
  borderRadius: 100,
  padding: "16px 48px",
  width: "max-content",
  backgroundColor: "rgba(0, 122, 255,1)",
  color: "white",
  fontWeight: "bold",
  fontSize: 19,
  lineHeight: 1.2,
  "&:hover": {
    cursor: "pointer",
    color: "white",
  },
  position: "absolute",
  [MEDIA_QUERIES.m.max]: {
    fontSize: 17.5,
    lineHeight: 1.2,
  },
  [MEDIA_QUERIES.xs.max]: {
    fontSize: 16,
    lineHeight: 1.2,
  },
};

const hoverButtonStyle = {
  backgroundImage:
    "linear-gradient(131.04deg, #F1B3C2 -30.65%, #7657ED 12.4%, #007AFF 71.98%, #81BCFD 114.38%)",
  "&:hover": {
    cursor: "pointer",
  },
};

const centerButtonStyle = {
  left: 0,
  right: 0,
  margin: "auto",
};

export default function Button({ centered = false, ...props }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      css={{ position: "relative", height: 56, display: "flex", width: "100%" }}
    >
      <div
        css={{ display: "flex" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <BaseButton centered={centered} {...props} />
        <CSSTransition
          in={isHovered}
          classNames="gradient-button"
          timeout={300}
          unmountOnExit
        >
          <HoveredButton centered={centered} {...props} />
        </CSSTransition>
      </div>
    </div>
  );
}

function BaseButton({ centered = false, ...props }) {
  const centerStyle = centered ? centerButtonStyle : {};

  if (props.href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a css={[buttonStyle, centerStyle]} {...props} />;
  }

  return <div css={[buttonStyle, centerStyle]} {...props} />;
}

function HoveredButton({ centered = false, ...props }) {
  const centerStyle = centered ? centerButtonStyle : {};

  if (props.href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a css={[buttonStyle, hoverButtonStyle, centerStyle]} {...props} />;
  }

  return <div css={[buttonStyle, hoverButtonStyle, centerStyle]} {...props} />;
}
