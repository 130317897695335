/** @jsxImportSource @emotion/react */
import Header from "../sections/Header";
import Footer from "../sections/Footer";

import { BREAKPOINTS } from "../theme";

export const PageSkeleton = ({ withSectionLinks = true, children }) => {
  return (
    <>
      <div
        css={{
          maxWidth: BREAKPOINTS[3],
          margin: "auto",
        }}
        id="page-container"
      >
        <Header withSectionLinks={withSectionLinks} />
        {children}
      </div>

      <div
        css={{
          background: "#F5F5F5",
        }}
      >
        <Footer />
      </div>
    </>
  );
};
