/** @jsxImportSource @emotion/react */
import StoreButton from "./StoreButton";

import { MEDIA_QUERIES } from "../theme";
import useDeviceUserAgent from "../hooks/useDeviceUserAgent";

export function StoreButtons({ customCSS = {}, centered = false }) {
  const { os } = useDeviceUserAgent();

  return (
    <div
      css={[
        {
          display: "flex",
          flexDirection: "row",
          marginBottom: 64,
          justifyContent: centered ? "center" : "",
          [MEDIA_QUERIES.m.max]: {
            marginBottom: 40,
          },
        },
        customCSS,
      ]}
    >
      {os === "ios" ? (
        <StoreButton type="appStore" centered />
      ) : os === "android" ? (
        <StoreButton type="playStore" centered />
      ) : (
        <>
          <StoreButton type="playStore" customCSS={{ marginRight: 32 }} />
          <StoreButton type="appStore" />
        </>
      )}
    </div>
  );
}

export default StoreButtons;
