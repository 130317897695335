import { useState, useEffect } from "react";
import useVisibilitySensor from "@rooks/use-visibility-sensor";

import useIsMobileWidth from "../hooks/useIsMobileWidth";

export default function useAutoStepper(ref, numOfSteps, interval = 5000) {
  const isMobileWidth = useIsMobileWidth();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { isVisible } = useVisibilitySensor(ref, {
    intervalCheck: 1000,
    partialVisibility: true,
  });

  useEffect(() => {
    if (!isVisible || isMobileWidth) {
      return;
    }

    const intervalID = setInterval(() => {
      const nextActiveStepIndex = activeStepIndex + 1;
      setActiveStepIndex(
        nextActiveStepIndex < numOfSteps ? nextActiveStepIndex : 0
      );
    }, interval);
    return () => clearInterval(intervalID);
  }, [
    activeStepIndex,
    setActiveStepIndex,
    interval,
    numOfSteps,
    isVisible,
    isMobileWidth,
  ]);

  return { activeStepIndex, setActiveStepIndex };
}
