/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import Text from "../components/Text";
import Button from "../components/Button";
import withMaxWidth from "../hoc/withMaxWidth";

import { ExternalLinks } from "../constants";
import { MEDIA_QUERIES } from "../theme";

export default function FeedbackSection() {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: "flex",
        backgroundColor: "#F9F9F9",
      }}
    >
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: 300,
            [MEDIA_QUERIES.m.max]: {
              paddingLeft: 16,
              paddingRight: 16,
            },
          }}
        >
          <Text
            type="sectionTitle"
            customCSS={{
              marginBottom: 32,
            }}
          >
            {t("feedback.title")}
          </Text>
          <Text
            type="sectionBody"
            customCSS={{
              marginBottom: 32,
            }}
          >
            {t("feedback.body")}
          </Text>
          <Button
            href={ExternalLinks.FEEDBACK_FORM}
            target="_blank noreferrer"
            centered
          >
            {t("feedback.form")}
          </Button>
        </div>
      )}
    </div>
  );
}
