/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import { MEDIA_QUERIES, SECTION_MARGIN_STYLE, BASE_FLEX_STYLE } from "../theme";

import CheckmarkIcon from "../images/checkmark.svg";

const ITEMS = [
  {
    title: "characteristics.firstTitle",
    body: "characteristics.firstBody",
  },
  {
    title: "characteristics.secondTitle",
    body: "characteristics.secondBody",
  },
  {
    title: "characteristics.thirdTitle",
    body: "characteristics.thirdBody",
  },
];

export default function CharacteristicsSection() {
  return (
    <div
      css={[
        BASE_FLEX_STYLE,
        SECTION_MARGIN_STYLE,
        {
          [MEDIA_QUERIES.l.max]: {
            paddingLeft: 16,
          },
          [MEDIA_QUERIES.s.max]: {
            paddingLeft: 0,
          },
        },
      ]}
    >
      {ITEMS.map((item, i) => (
        <CharacteristicBox
          key={`characteristic-box-${i}`}
          {...item}
          index={i}
        />
      ))}
    </div>
  );
}

function CharacteristicBox({ title, body }) {
  const { t } = useTranslation();

  return (
    <div
      css={{
        flex: 1,
        backgroundColor: "rgba(249, 249, 249, 1)",
        padding: 36,
        marginRight: 16,
        [MEDIA_QUERIES.s.max]: {
          marginLeft: 32,
          marginRight: 32,
          marginBottom: 16,
        },
        [MEDIA_QUERIES.xs.max]: {
          marginLeft: 16,
          marginRight: 16,
        },
      }}
    >
      <div css={{ display: "flex" }}>
        <div
          css={{
            backgroundColor: "rgba(208, 251, 233, 1)",
            borderRadius: 100,
            flexDirection: "row",
            display: "flex",
            padding: "8px 16px",
          }}
        >
          <img src={CheckmarkIcon} alt="Checkmark icon" />
          <div
            css={{
              color: "rgba(14, 195, 144, 1)",
              fontWeight: "bold",
              fontSize: 19,
              lineHeight: 1.2,
              marginLeft: 8,
            }}
          >
            {t(title)}
          </div>
        </div>
      </div>
      <div
        css={{
          color: "rgba(18, 18, 31, 1)",
          fontStyle: "normal",
          fontSize: 14,
          lineHeight: 1.5,
          paddingTop: 8,
        }}
      >
        {t(body)}
      </div>
    </div>
  );
}
