/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from "react";
import { Element } from "react-scroll";
import useVisibilitySensor from "@rooks/use-visibility-sensor";
import { useTranslation } from "react-i18next";

import Text from "./Text";

import { MEDIA_QUERIES } from "../theme";

export default function Step({
  stepNumber,
  stepTitle,
  stepBody,
  onClick,
  isActive,
  scrollElementID,
  onVisible,
  onInvisible,
  hideMarginBottom = false,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { isVisible } = useVisibilitySensor(ref, {
    scrollCheck: true,
    intervalCheck: 500,
  });

  useEffect(() => {
    if (isVisible && onVisible) {
      onVisible();
    }
  }, [isVisible, onVisible, onInvisible]);

  return (
    <Element name={scrollElementID}>
      <div
        ref={ref}
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          opacity: isActive ? 1 : 0.3,
          marginBottom: hideMarginBottom ? 0 : 36,
          ":hover": {
            cursor: "pointer",
          },
          [MEDIA_QUERIES.s.max]: {
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
            width: 300,
            marginRight: 16,
          },
        }}
        onClick={onClick}
      >
        <div
          css={{
            display: "flex",
            alignSelf: "flex-start",
            marginTop: 20,
            [MEDIA_QUERIES.s.max]: {
              marginTop: 0,
            },
          }}
        >
          <div
            css={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: 18,
              width: 32,
              height: 32,
              borderRadius: 16,
              border: "2px solid #12121F",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 16,
              [MEDIA_QUERIES.s.max]: {
                fontSize: 12,
                width: 22,
                height: 22,
                borderRadius: 11,
              },
            }}
          >
            {stepNumber}
          </div>
        </div>
        <div>
          <div
            css={{
              marginTop: 16,
              marginBottom: 8,
              [MEDIA_QUERIES.s.max]: {
                marginTop: 0,
              },
            }}
          >
            <Text
              type="stepTitle"
              customCSS={{
                [MEDIA_QUERIES.s.max]: {
                  lineHeight: 1,
                },
              }}
            >
              {t(stepTitle)}
            </Text>
          </div>
          <Text type="sectionBody">{t(stepBody)}</Text>
        </div>
      </div>
    </Element>
  );
}
