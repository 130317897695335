/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import Text from "../components/Text";
import Button from "../components/Button";

import { MEDIA_QUERIES, BASE_FLEX_STYLE, SECTION_MARGIN_STYLE } from "../theme";
import { ExternalLinks } from "../constants";
import { useIsSmallMobileWidth } from "../hooks/useIsMobileWidth";

import MultiHopImage from "../images/multi-hop.svg";

export default function MultiHopSection() {
  const { t } = useTranslation();
  const isSmallMobileWidth = useIsSmallMobileWidth();

  return (
    <div css={[BASE_FLEX_STYLE, SECTION_MARGIN_STYLE]}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingRight: 16,
        }}
      >
        <img
          className="lozad"
          src={MultiHopImage}
          alt="multi hop"
          css={{
            width: "100%",
            [MEDIA_QUERIES.s.max]: {
              width: "100vw",
            },
          }}
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: 16,
          flex: 1,
          [MEDIA_QUERIES.l.max]: {
            paddingRight: 16,
          },
          [MEDIA_QUERIES.s.max]: {
            paddingLeft: 32,
            paddingRight: 32,
            marginTop: 32,
          },
          [MEDIA_QUERIES.xs.max]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        }}
      >
        <div>
          <div css={{ marginBottom: 24 }}>
            <Text type="sectionTitle">{t("multiHop.title")}</Text>
          </div>
          <div css={{ marginBottom: 32 }}>
            <Text type="sectionBody">{t("multiHop.body")}</Text>
          </div>
          <Button
            href={ExternalLinks.USER_GUIDE_SEND_AND_REQUEST}
            target="_blank noreferrer"
            centered={isSmallMobileWidth}
          >
            {t("userGuide")}
          </Button>
        </div>
      </div>
    </div>
  );
}
