/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "./Text";

import useComponentVisible from "../hooks/useComponentVisible";

import GreatBritainIcon from "../images/gb.svg";
import SpainIcon from "../images/es.svg";
import ChevronDownIcon from "../images/chevron-down.svg";

const AVAILABLE_LANGUAGES = {
  en: {
    languageCode: "en",
    getFlagIcon: () => GreatBritainIcon,
    getLabel: () => "English",
  },
  es: {
    languageCode: "es",
    getFlagIcon: () => SpainIcon,
    getLabel: () => "Español",
  },
};

export default function LanguagePicker() {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const { i18n } = useTranslation();
  const [selectedLanguageKey, setSelectedLanguageKey] = useState(
    i18n.language.split("-")[0]
  );

  const selectLanguage = (languageKey) => {
    setSelectedLanguageKey(languageKey);
    i18n.changeLanguage(languageKey);
    setIsComponentVisible(false);
  };

  const language =
    AVAILABLE_LANGUAGES[selectedLanguageKey] || AVAILABLE_LANGUAGES.en;

  return (
    <div css={{ display: "flex" }}>
      <LanguagePickerItem
        language={language}
        withChevron
        onClick={() => setIsComponentVisible(true)}
      />
      {isComponentVisible && (
        <LanguagePickerDropdown
          ref={ref}
          onClickItem={(languageKey) => selectLanguage(languageKey)}
        />
      )}
    </div>
  );
}

const LanguagePickerDropdown = React.forwardRef(({ onClickItem }, ref) => (
  <div
    ref={ref}
    css={{
      width: 90,
      backgroundColor: "#F9F9F9",
      position: "absolute",
      top: 60 + 24,
      paddingTop: 8,
      paddingLeft: 8,
      paddingBottom: 8,
      gap: 16,
      zIndex: 3,
      ":after": {
        bottom: "100%",
        left: "50%",
        border: "solid transparent",
        content: '""',
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
        borderBottomColor: "#F9F9F9",
        borderWidth: 10,
        marginLeft: -10,
      },
    }}
  >
    {Object.keys(AVAILABLE_LANGUAGES).map((languageKey) => (
      <LanguagePickerItem
        key={languageKey}
        language={AVAILABLE_LANGUAGES[languageKey]}
        onClick={() => onClickItem(languageKey)}
      />
    ))}
  </div>
));

function LanguagePickerItem({ language = {}, withChevron, onClick }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: withChevron ? "center" : "flex-start",
        alignItems: "center",
        gap: 4,
        width: 108,
        padding: withChevron ? 0 : 8,
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <img src={language.getFlagIcon()} alt="flag icon" />
      <Text type="languageLabel" label={language.getLabel()} />
      {withChevron && <img src={ChevronDownIcon} alt="chevron down icon" />}
    </div>
  );
}
