/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Events } from "react-scroll";
import { useTranslation } from "react-i18next";

import HeaderLink from "../components/HeaderLink";
import LanguagePicker from "../components/LanguagePicker";
import Text from "../components/Text";

import useWindowDimensions from "../hooks/useWindowDimensions";
import { MEDIA_QUERIES, BREAKPOINTS } from "../theme";

import MenuIcon from "../images/menu.svg";
import XIcon from "../images/x.svg";

const NAV_ITEMS = [
  {
    to: "about-section",
    translationKey: "header.about",
  },
  {
    to: "features-section",
    translationKey: "header.features",
  },
  {
    to: "ecosystem-section",
    translationKey: "header.ecosystem",
  },
  {
    to: "connect-section",
    translationKey: "header.connect",
  },
];

const linkWrapperStyle = {
  marginRight: 36,
  [MEDIA_QUERIES.l.max]: {
    marginRight: 28,
  },
  [MEDIA_QUERIES.m.max]: {
    marginRight: 16,
  },
};

export default function Header({ withSectionLinks = true }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const isMaxMediumWidth = width < BREAKPOINTS[1];

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {
      setIsMenuOpen(false);
    });
    return () => Events.scrollEvent.remove("begin");
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        height: 60,
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 32,
        [MEDIA_QUERIES.l.max]: {
          height: 40,
          paddingLeft: 16,
          paddingRight: 16,
        },
        [MEDIA_QUERIES.xs.max]: {
          paddingTop: 16,
        },
      }}
    >
      <Link to="/">
        <span
          css={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: 21,
            textTransform: "capitalize",
            color: "#AAAABB",
            "&:hover": {
              cursor: "pointer",
              color: "#007AFF",
              transition: "color 200ms ease-in",
            },
          }}
        >
          Trustlines App
        </span>
      </Link>
      {isMaxMediumWidth ? (
        <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <img src={XIcon} alt="Close menu icon" />
          ) : (
            <img src={MenuIcon} alt="Menu icon" />
          )}
        </div>
      ) : (
        <>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {withSectionLinks
              ? NAV_ITEMS.map((item, i) => (
                  <div
                    key={`header-link-${item.translationKey}`}
                    css={i === NAV_ITEMS.length - 1 ? {} : linkWrapperStyle}
                  >
                    <HeaderLink to={item.to} label={t(item.translationKey)} />
                  </div>
                ))
              : null}
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ContactUsButton />
            <LanguagePicker />
          </div>
        </>
      )}
      {isMenuOpen && <FullScreenMenu />}
    </div>
  );
}

function FullScreenMenu({ withSectionLinks = true }) {
  const { t } = useTranslation();

  return (
    <div
      css={{
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 100,
        top: 90,
        left: 0,
        backgroundColor: "white",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: 56,
          paddingTop: 56,
          height: "70%",
        }}
      >
        <div>
          {withSectionLinks
            ? NAV_ITEMS.map((item) => (
                <div
                  css={{ marginBottom: 32 }}
                  key={`nav-item-${item.translationKey}`}
                >
                  <HeaderLink to={item.to} label={t(item.translationKey)} />
                </div>
              ))
            : null}
        </div>
        <ContactUsButton />
        <LanguagePicker />
      </div>
    </div>
  );
}

function ContactUsButton() {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      css={{
        width: 151,
        height: 60,
        background: "rgba(170, 170, 187, 0.13)",
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ":hover": {
          cursor: "pointer",
          background: "#E6EFFC",
          transition: "background 200ms ease-in",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => (window.location.href = "mailto:contact@trustlines.app")}
    >
      <Text
        type="headerLink"
        label={t("header.contact")}
        customCSS={
          isHovered
            ? {
                color: "#007AFF",
                transition: "color 200ms ease-in",
              }
            : {}
        }
      />
    </div>
  );
}
