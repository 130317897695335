/** @jsxImportSource @emotion/react */
import Text from "./Text";

import useDeviceUserAgent from "../hooks/useDeviceUserAgent";
import { ExternalLinks } from "../constants";

export default function DownloadAPK() {
  const { os, isMobile } = useDeviceUserAgent();

  if (isMobile) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          zIndex: 10,
        }}
      >
        {os === "ios" ? (
          <Text
            type="downloadLink"
            isAnchor
            href={ExternalLinks.PLAY_STORE}
            target="_blank noreferrer"
          >
            Play Store
          </Text>
        ) : (
          <Text
            type="downloadLink"
            isAnchor
            href={ExternalLinks.APP_STORE}
            target="_blank noreferrer"
          >
            App Store
          </Text>
        )}
        <div css={{ marginLeft: 4, marginRight: 4 }}>
          <Text
            type="downloadLink"
            customCSS={{
              textDecoration: "none",
            }}
          >
            or
          </Text>
        </div>
        <Text isAnchor type="downloadLink" href={ExternalLinks.APK} download>
          .APK
        </Text>
      </div>
    );
  }

  return (
    <div css={{ display: "flex" }}>
      <Text isAnchor type="downloadLink" href={ExternalLinks.APK} download>
        Download .APK
      </Text>
    </div>
  );
}
