/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "../components/Text";

import withMaxWidth from "../hoc/withMaxWidth";
import { MEDIA_QUERIES } from "../theme";
import { ExternalLinks } from "../constants";

import TwitterIcon from "../images/twitter-icon.svg";
import TelegramIcon from "../images/telegram-icon.svg";
import ForumIcon from "../images/forum-icon.svg";
import ArrowRightIcon from "../images/arrow-right-2.svg";

const ITEMS = [
  {
    icon: TwitterIcon,
    title: "connect.twitter",
    body: "connect.twitterText",
    link: ExternalLinks.TWITTER,
  },
  {
    icon: ForumIcon,
    title: "connect.forum",
    body: "connect.forumText",
    link: ExternalLinks.FORUM,
  },
  {
    icon: TelegramIcon,
    title: "connect.telegram",
    body: "connect.telegramText",
    link: ExternalLinks.TELEGRAM,
  },
];

export default function ConnectWithUsSection() {
  const { t } = useTranslation();

  return (
    <div
      id="connect-section"
      css={{
        display: "flex",
        backgroundColor: "white",
      }}
    >
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: 56,
            [MEDIA_QUERIES.l.max]: {
              padding: 32,
            },
            [MEDIA_QUERIES.xs.max]: {
              paddingLeft: 16,
              paddingRight: 16,
              height: "100%",
            },
          }}
        >
          <Text
            type="sectionTitle"
            customCSS={{
              marginBottom: 32,
            }}
          >
            {t("connect.title")}
          </Text>
          <Text
            type="sectionBody"
            customCSS={{
              marginBottom: 32,
            }}
          >
            {t("connect.body")}
          </Text>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              [MEDIA_QUERIES.m.max]: {
                flexDirection: "column",
              },
            }}
          >
            {ITEMS.map((item) => (
              <ConnectBox key={`${item.title}`} {...item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function ConnectBox({ title, icon, body, link }) {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();

  return (
    <a
      css={{
        backgroundColor: "#FFF9F9",
        padding: 40,
        paddingBottom: 50,
        height: 280,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 32,
        color: "#12121F",
        ":hover": {
          backgroundColor: "black",
          transition: "background-color 200ms ease-in",
          cursor: "pointer",
          color: "white",
        },
        [MEDIA_QUERIES.m.max]: {
          marginRight: 0,
          marginBottom: 16,
          flex: "none",
          height: 200,
        },
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      href={link}
      target="_blank noreferrer"
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 32,
        }}
      >
        <img src={icon} alt="icon" />
        <img src={ArrowRightIcon} alt="icon" />
      </div>
      <div
        css={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 32,
          lineHeight: 1.4,
          marginBottom: 80,
          transition: "color 200ms ease-in",
          [MEDIA_QUERIES.m.max]: {
            fontSize: 24,
            lineHeight: 1.4,
          },
          [MEDIA_QUERIES.xs.max]: {
            fontSize: 21,
            lineHeight: 1.4,
          },
        }}
      >
        {t(title)}
      </div>
      <div
        css={{
          color: isHover ? "#EDEDED" : "#6C6C73",
          fontStyle: "normal",
          fontSize: 14,
          lineHeight: 1.6,
          alignSelf: "flex-end",
          transition: "color 200ms ease-in",
          width: "100%",
          [MEDIA_QUERIES.xs.max]: {
            fontSize: 12,
            lineHeight: 1.6,
          },
        }}
      >
        {t(body)}
      </div>
    </a>
  );
}
