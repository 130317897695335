export const PRIVACY_POLICY_PART_1 = `
Privacy Policy
==============

Effective date: April 09, 2020

This Privacy Policy describes how your Personal Information is collected, used, and shared by Auxiliary GmbH. Personal Information means any information relating to an identified or identifiable natural person, who may be identified, directly or indirectly by reference to an identifier such as a name, an identification number, location data, online information (e.g. an IP address) or to one or more factors relating to that person. This notice applies to Personal Information provided to us by visitors to https://trustlines.app (the “Site”), end users, beta testers, suppliers, contractors, job applicants, or other direct or indirect affected persons of the activities we carry out and functions we perform in running the Site. In this notice “you” refers to any individual whose Personal Information we hold or process.

Basis on which we process personal data
-----------

Personal Information we hold about you will be processed either because:

• the processing is necessary in pursuit of a “legitimate interest” (Art. 6 Para. 1 lit. f GDPR); a legitimate interest in this context means a valid interest we have or a third party has in processing your personal data which is not overridden by your interests in data privacy and security;\n
• you have consented to the processing for the specific purposes described in this notice (Art. 6 Para. 1 lit. a GDPR); or\n
• the processing serves to fulfill a contract to which the data subject is a party or to carry out pre-contractual measures (Art. 6 Para. 1 lit. b GDPR)\n
• the processing is necessary in order for us to comply with our obligations under a contract between you and us.

Personal information we collect
-----------

When you visit the Site, we may automatically collect certain information about you and your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we may collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.” We collect Device Information using the following technologies:

• “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.

Additionally, if you sign up for a newsletter through the Site, we collect your name and email address. When registering via your Github account, we may additionally have access to your Github username. When registering via your Twitter account, we may additionally have access to your Twitter account information such as your tweets, settings and blocked users. (We're not storing this information from Twitter, the email address is what is used for the account creation at the forum, the app is a Twitter app using the API and that's why it asks for such a list of items.) We refer to all this information above as “Subscription Information.” In the course of our interactions, we may also collect a record and details of any correspondence or communications between you and us relating to any information request or job application submitted to us. We refer to this information as “Communication Information”. When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information, Subscription Information and Communication Information.

How do we use your personal information?
-----------

Your Subscription Information will only be used to send you the subscribed newsletter by email. Your name is given so that we can address you personally in the newsletter and, if necessary, identify you if you want to exercise your rights as a data subject. To receive the newsletter, it is sufficient to enter your email address. When you register to receive our newsletter, the data you provide will only be used for this purpose. Subscribers can also be informed via email about circumstances that are relevant to the service or registration (e.g. changes to the newsletter offer or technical conditions). For an effective registration, we need a valid email address. In order to check that a registration is actually made by the owner of an email address, we use the "double opt-in" procedure. For this purpose, we log the order of the newsletter, the sending of a confirmation email and the receipt of the response requested. Further data is not collected. The data will only be used for sending the newsletter and will not be passed on to third parties.

We use or may use the Subscription Information regarding our other personalized services and Communication Information that we collect generally to communicate with you and to provide any other information you have requested or respond to any other correspondence.

We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (ensuring a problem-free connection to the website, evaluating system security and stability and for other administrative purposes). (This processing for our legitimate interests in managing the information we provide via our Site and to assess how people use the Site.) We do not use your Personal Information to draw conclusions about you personally. Personal Information may be statistically evaluated by us in order to optimize our website and the technology behind it.

Sharing your personal information
-----------

We share your Personal Information with third parties such as processors to help us use your Personal Information, as described above. We may disclose your Personal Information to other third-parties that enable us to provide the Site and any connected services including technical support providers who may have access to personal data. These may include software and website developers, IT support and mail management service providers. In all cases, our agreement with the relevant third party will contain appropriate provisions regarding the use and security of your Personal Information. Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.

Do not track
-----------

Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.

Transferring your information outside Europe
-----------

We will not transfer any data in a systematic way outside of the EU but there may be circumstances in which certain personal information is transferred outside of the EU, in particular:

• if you live outside of the EU, we may communicate with you during the course of our interactions and such communications may include Personal Information (such as Subscription Information)\n
• we may have certain third-party service suppliers who are based outside the EU or who store data outside of the EU;\n
• if you communicate with us while you are outside of the EU there may be some data transfer to you or your device;\n
• from time to time your information may be stored in devices which are used by affiliates engaged by us when outside of the EU.\n

If we transfer your information outside of the EU in a systematic way, and the third country or international organisation in question has not been deemed by the EU Commission to have adequate data protection laws, we will provide appropriate safeguards and your privacy rights will continue to be enforceable against us as outlined in this notice.

Your rights
-----------

In accordance with the GDPR and data protection laws applicable to you, the following rights may be available to you: The right (1) to be informed about our data processing activities (Art. 15 GDPR) (2) of access to the Personal Information we hold about you (Art. 15 GDPR) (3) to correction of your Personal Information if it is incomplete or inaccurate (Art. 16 GDPR) (4) to deletion of your Personal Information (Art. 17 GDPR) or (5) to restriction or limitation of the processing of your Personal Information (Art. 18 GDPR) and (6) to object to the processing of your Personal Information (Art. 21 GDPR) and (7) to receive copies of your Personal Information in a commonly used form and to transfer it directly to a third party (Art. 20 GDPR). If we are relying on your consent as the basis on which we are processing your Personal Information, you have the right at any time to withdraw your consent with future effect. Regarding your newsletter subscription, there is a corresponding link in every newsletter. You can also unsubscribe directly from this website at any time or inform us of your revocation. Any request made under (2) above (a subject access request) or any other notification in respect of your rights must be sent to us by email to: contact@trustlines.app or by post to: Auxiliary GmbH, Taunusstr. 61, 55120 Mainz, Germany.

If you are of the opinion that our data processing activities infringe upon applicable data protection regulations or your personal data protection rights, you have the rights to report this to the competent data protection authority in Germany. A list of all data protection authorities ist available [here](https://www.datenschutz.rlp.de/de/themenfelder-themen/datenschutzkontrolle-bundlaender/).

Data retention
-----------

If you sign up for a newsletter through the Site or we acquire your Personal Information due to another interaction with us, we will generally maintain your Personal Information for our records for twelve months unless you ask us to delete this information before such time has passed. We may retain your Personal Information for more than twelve months if we consider it is necessary to enable us to satisfactorily provide the information on the Site or administer the Site or to enable us to comply with any statutory, legal or contractual obligation we may have. We review the Personal Information (and the categories of personal data) we are holding on a regular basis to ensure the data we are holding is still accurate and is still relevant to us and the Site. If we discover that certain data we are holding is no longer necessary or accurate, we will take reasonable steps to correct or delete this data as may be required. Generally the Personal Information will be deleted as soon as it is no longer required for the purpose of the collection. Regarding Device Information this is generally the case when the respective session has ended. The server log files will be deleted after 7 days.

Security
-----------

We will take all reasonable steps to ensure that appropriate technical and organisational measures are carried out in order to safeguard your Personal Information and protect against unlawful access and accidental loss or damage. These measures may include (as necessary):

• compliance with our internal IT security procedures;\n
• protecting our servers by both hardware and software firewalls;\n
• locating our data processing storage facilities in secure locations;\n
• using appropriate support tool and mailing list management providers;\n
• when necessary, disposing of or deleting your data in a secure manner; and\n
• regularly backing up and encrypting all data we hold.\n

We will ensure that all contractors, staff or individuals engaged by us are aware of their privacy and data security obligations. We will take reasonable steps to ensure that the employees of third parties working on our behalf or providing services to us are aware of their privacy and data security obligations. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted by you to us by email. Once we have received your information, we will use the procedures and security features referred to in this Privacy Policy to try to prevent unauthorised access.

Web analysis tool Matomo
-----------

We analyze the use of our website with the open source web analysis tool Matomo (formerly Piwik; data protection declaration can be found at https://matomo.org/privacy-policy/). For this purpose, the automatically collected IP addresses are anonymized before the evaluation. The analysis is therefore based on anonymized data records and there is no personal evaluation. The web analysis is used exclusively to optimize the website in terms of user-friendliness and to provide useful information about our services. This data is not merged with other personal data sources or passed on to third parties.
`;

export default PRIVACY_POLICY_PART_1;
