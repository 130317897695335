/** @jsxImportSource @emotion/react */

export const BREAKPOINTS = [576, 768, 992, 1210];

export const MEDIA_QUERIES = {
  xs: {
    max: `@media only screen and (max-width: ${BREAKPOINTS[0]}px)`,
  },
  s: {
    min: `@media only screen and (min-width: ${BREAKPOINTS[0]}px)`,
    max: `@media only screen and (max-width: ${BREAKPOINTS[1]}px)`,
  },
  m: {
    min: `@media only screen and (min-width: ${BREAKPOINTS[1]}px)`,
    max: `@media only screen and (max-width: ${BREAKPOINTS[2]}px)`,
  },
  l: {
    min: `@media only screen and (min-width: ${BREAKPOINTS[2]}px)`,
    max: `@media only screen and (max-width: ${BREAKPOINTS[3]}px)`,
  },
  xl: {
    min: `@media only screen and (min-width: ${BREAKPOINTS[3]}px)`,
  },
};

export const BASE_FLEX_STYLE = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  [MEDIA_QUERIES.s.max]: {
    flexDirection: "column",
  },
};

export const SECTION_MARGIN_STYLE = {
  marginBottom: 112,
  [MEDIA_QUERIES.s.max]: {
    marginBottom: 112,
  },
};
