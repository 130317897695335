/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";

import Text from "./Text";
import Button from "./Button";
import Step from "./Step";
import StoreButtons from "./StoreButtons";

import { MEDIA_QUERIES, BASE_FLEX_STYLE, SECTION_MARGIN_STYLE } from "../theme";
import useAutoStepper from "../hooks/useAutoStepper";
import useIsMobileWidth, {
  useIsSmallMobileWidth,
} from "../hooks/useIsMobileWidth";

import ArrowRightBlueIcon from "../images/arrow-right-blue.svg";
import ArrowLeftBlueIcon from "../images/arrow-left-blue.svg";

const reverseStyle = {
  flexDirection: "row-reverse",
};

export default function ImageWithSteps({
  id = "",
  steps = [],
  scrollContainerID,
  reverse = false,
  sectionTitle,
  sectionBody,
  userGuideLink,
  hideMarginBottomLastStep = false,
}) {
  const ref = useRef(null);
  const { activeStepIndex, setActiveStepIndex } = useAutoStepper(
    ref,
    steps.length
  );
  const isMobileWidth = useIsMobileWidth();
  const isSmallMobileWidth = useIsSmallMobileWidth();

  const scrollToStepIndex = (stepIndex) => {
    if (isMobileWidth) {
      if (!isSmallMobileWidth) {
        setActiveStepIndex(stepIndex);
      }
      const { id } = steps[stepIndex];
      scroller.scrollTo(id, {
        horizontal: true,
        smooth: true,
        offset: -16,
        containerId: scrollContainerID,
        ignoreCancelEvents: true,
      });
    }
  };

  const handleClickLeftArrow = () => {
    const prevIndex = activeStepIndex - 1;

    if (prevIndex < 0) {
      return;
    }
    scrollToStepIndex(prevIndex);
  };

  const handleClickRightArrow = () => {
    const nextIndex = activeStepIndex + 1;

    if (nextIndex > steps.length - 1) {
      return;
    }
    scrollToStepIndex(nextIndex);
  };

  const activeStep = steps[activeStepIndex];

  return (
    <div
      id={id}
      ref={ref}
      css={[
        BASE_FLEX_STYLE,
        SECTION_MARGIN_STYLE,
        { overflowX: "hidden", overflowY: "hidden" },
        reverse ? reverseStyle : {},
      ]}
    >
      {isMobileWidth ? (
        <UpperSection
          title={sectionTitle}
          body={sectionBody}
          link={userGuideLink}
          withStoreButtons={reverse}
        />
      ) : null}
      <GradientBoxWithPhone
        phoneImgSrc={activeStep.imgSrc}
        phoneImgSrcSet={activeStep.imgSrcSet}
        reverse={reverse}
      />
      <div
        id={scrollContainerID}
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 16,
          [MEDIA_QUERIES.s.max]: {
            paddingRight: 16,
          },
          [MEDIA_QUERIES.s.max]: {
            paddingLeft: 16,
            paddingRight: 16,
            flexDirection: "row",
            width: "90%",
            marginRight: 300,
            maxWidth: 1400,
            overflowX: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              width: 0,
              background: "transparent",
            },
          },
        }}
      >
        {isMobileWidth ? null : (
          <UpperSection
            title={sectionTitle}
            body={sectionBody}
            link={userGuideLink}
            withStoreButtons={reverse}
          />
        )}
        {steps.map((item, i) => (
          <Step
            key={`${scrollContainerID}-step-${i + 1}`}
            scrollElementID={item.id}
            stepNumber={i + 1}
            stepTitle={item.title}
            stepBody={item.body}
            isActive={activeStepIndex === i}
            hideMarginBottom={
              hideMarginBottomLastStep ? i === steps.length - 1 : false
            }
            onClick={() => {
              if (isMobileWidth) {
                scrollToStepIndex(i);
              } else {
                setActiveStepIndex(i);
              }
            }}
            onVisible={() => {
              if (isSmallMobileWidth && activeStepIndex !== i) {
                setActiveStepIndex(i);
              }
            }}
          />
        ))}
      </div>
      {isMobileWidth ? (
        <SliderNavigator
          onClickLeftArrow={handleClickLeftArrow}
          onClickRightArrow={handleClickRightArrow}
          activeStepIndex={activeStepIndex}
          numOfSteps={steps.length}
        />
      ) : null}
    </div>
  );
}

function UpperSection({ title, body, link, withStoreButtons = false }) {
  const isSmallMobileWidth = useIsSmallMobileWidth();
  const { t } = useTranslation();

  return (
    <div
      css={{
        [MEDIA_QUERIES.s.max]: {
          paddingLeft: 32,
          paddingRight: 32,
        },
        [MEDIA_QUERIES.xs.max]: {
          marginBottom: 24,
          paddingLeft: 16,
          paddingRight: 16,
        },
      }}
    >
      <div css={{ marginBottom: 24 }}>
        <Text type="sectionTitle">{t(title)}</Text>
      </div>
      <div css={{ marginBottom: 24 }}>
        <Text type="sectionBody">{body}</Text>
      </div>
      <div css={{ marginBottom: 24 }}>
        {withStoreButtons ? (
          <StoreButtons
            customCSS={{
              [MEDIA_QUERIES.m.max]: {
                marginBottom: 40,
              },
            }}
            centered={isSmallMobileWidth}
          />
        ) : (
          <Button
            href={link}
            target="_blank noreferrer"
            centered={isSmallMobileWidth}
          >
            {t("userGuide")}
          </Button>
        )}
      </div>
    </div>
  );
}

function GradientBoxWithPhone({ phoneImgSrc, phoneImgSrcSet, reverse }) {
  const padding = reverse ? { paddingLeft: 16 } : { paddingRight: 16 };
  return (
    <div
      css={[
        {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          position: "relative",
          [MEDIA_QUERIES.s.max]: {
            padding: 0,
          },
        },
        padding,
      ]}
    >
      <div
        className="lozad"
        css={{
          width: "100%",
          height: 600,
          zIndex: 0,
          marginTop: 52,
          background:
            "linear-gradient(131.04deg, #F1B3C2 -30.65%, #7657ED 12.4%, #007AFF 71.98%, #81BCFD 114.38%)",
          transform: "rotate(-180deg)",
          [MEDIA_QUERIES.l.max]: {
            height: 508,
          },
          [MEDIA_QUERIES.s.max]: {
            objectFit: "cover",
            width: "100vw",
            height: 300,
            marginBottom: 120,
          },
        }}
      />
      <picture css={{ display: "contents" }}>
        <source
          media="(max-width: 768px)"
          srcSet={`${phoneImgSrc} 1x, ${phoneImgSrc} 2x`}
        />
        <img
          className="lozad"
          css={{
            maxWidth: "100%",
            height: "auto",
            zIndex: 1,
            position: "absolute",
            left: 0,
            right: 0,
            margin: "auto",
            [MEDIA_QUERIES.l.max]: {
              height: 610,
            },
            [MEDIA_QUERIES.s.max]: {
              height: 400,
            },
          }}
          src={phoneImgSrc}
          srcSet={phoneImgSrcSet}
          alt="Phone with trustlines app"
        />
      </picture>
    </div>
  );
}

const inactiveOpacity = {
  opacity: 0.5,
};

function SliderNavigator({
  onClickLeftArrow,
  onClickRightArrow,
  activeStepIndex,
  numOfSteps,
}) {
  const leftArrowOpacity = activeStepIndex === 0 ? inactiveOpacity : {};
  const rightArrowOpacity =
    activeStepIndex === numOfSteps - 1 ? inactiveOpacity : {};

  return (
    <div
      css={[
        {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 32,
          paddingRight: 32,
          [MEDIA_QUERIES.xs.max]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      ]}
    >
      <div
        css={[{ display: "flex" }, leftArrowOpacity]}
        onClick={onClickLeftArrow}
      >
        <img src={ArrowLeftBlueIcon} alt="Arrow icon left" />
      </div>
      <div
        css={[{ display: "flex" }, rightArrowOpacity]}
        onClick={onClickRightArrow}
      >
        <img src={ArrowRightBlueIcon} alt="Arrow icon right" />
      </div>
    </div>
  );
}
