/** @jsxImportSource @emotion/react */
import { BREAKPOINTS } from "../theme";

const maxWidth = {
  maxWidth: BREAKPOINTS[3],
  margin: "auto",
  width: "100%",
};

export default function withMaxWidth(component) {
  return <div css={maxWidth}>{component}</div>;
}
