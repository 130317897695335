/** @jsxImportSource @emotion/react */
import StoreButton from "../components/StoreButton";
import withMaxWidth from "../hoc/withMaxWidth";

import { MEDIA_QUERIES } from "../theme";

export default function StoreButtonsSection() {
  return (
    <div
      css={{
        display: "flex",
        height: 240,
        [MEDIA_QUERIES.s.max]: {
          height: 200,
        },
      }}
    >
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            [MEDIA_QUERIES.xs.max]: {
              flexDirection: "column",
            },
          }}
        >
          <StoreButton
            type="playStore"
            centered
            customCSS={{
              marginRight: 32,
              [MEDIA_QUERIES.xs.max]: {
                marginRight: 0,
                marginBottom: 32,
              },
            }}
          />
          <StoreButton type="appStore" />
        </div>
      )}
    </div>
  );
}
