/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import Text from "./Text";
import StoreButtons from "./StoreButtons";
import DownloadAPK from "./DownloadAPK";

import { MEDIA_QUERIES } from "../theme";
import useDeviceUserAgent from "../hooks/useDeviceUserAgent";

export default function LeftHero() {
  const { t } = useTranslation();
  const { isMobile } = useDeviceUserAgent();

  return (
    <div
      css={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 16,
        [MEDIA_QUERIES.l.max]: {
          paddingLeft: 16,
          paddingRight: 0,
        },
        [MEDIA_QUERIES.s.max]: {
          alignItems: "center",
          paddingRight: 16,
          paddingTop: 68,
        },
        [MEDIA_QUERIES.xs.max]: {
          alignItems: "center",
          paddingRight: 16,
          paddingTop: 40,
        },
      }}
    >
      <div css={{ marginBottom: 0 }}>
        <Text
          type="hero"
          dangerouslySetInnerHTML={{ __html: t("hero.first") }}
        />
      </div>
      <div css={{ marginBottom: 16 }}>
        <Text
          type="hero"
          dangerouslySetInnerHTML={{ __html: t("hero.second") }}
        />
      </div>
      <div
        css={{
          marginBottom: 52,
          [MEDIA_QUERIES.m.max]: {
            marginBottom: 40,
          },
        }}
      >
        <Text type="subhero">
          {t("subHero.first")}
          <br />
          {t("subHero.second")}
        </Text>
      </div>
      <StoreButtons />
      {isMobile ? <DownloadAPK /> : null}
    </div>
  );
}
