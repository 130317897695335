export const IMPRINT = `
Imprint
=========

Provider according to German Telecommunication Act (Anbieter i.S.d. TMG)\n
&nbsp;\n
**Airbot GmbH**\n
**Am Flugplatz 5 c**\n
**55126 Mainz**\n
**Germany**\n
&nbsp;\n
Phone: +49 (0) 6131 2116391\n
Fax: +49 (0) 6131 2116392\n
email: contact@trustlines.app\n
&nbsp;\n
Managing Director (Geschäftsführer): Heiko Hees\n
Register court (Registergericht): Amtsgericht Mainz, Germany\n
Registration Number (Registernummer): 8375 Mainz\n
&nbsp;\n
Author and responsible according to Section 55 (2) German Interstate Broadcasting Treaty (Autor und Verantwortlicher i.S.d. § 55 Abs. 2 RStV):\n
Herr Heiko Hees, ℅ Auxiliary GmbH, Taunusstr. 61, 55120 Mainz, Germany
`;

export default IMPRINT;
