/** @jsxImportSource @emotion/react */
import Text from "../components/Text";
import Button from "../components/Button";
import { useTranslation, Trans } from "react-i18next";

import useIsMobileWidth, {
  useIsSmallMobileWidth,
} from "../hooks/useIsMobileWidth";
import { MEDIA_QUERIES, BASE_FLEX_STYLE, SECTION_MARGIN_STYLE } from "../theme";
import { ExternalLinks } from "../constants";

import AboutImage from "../images/about.svg";

export default function AboutSection() {
  const isMobileWidth = useIsMobileWidth();
  const isSmallMobileWidth = useIsSmallMobileWidth();
  const { t } = useTranslation();

  return (
    <>
      {isMobileWidth && <div css={SECTION_MARGIN_STYLE} />}
      <div id="about-section" css={[BASE_FLEX_STYLE, SECTION_MARGIN_STYLE]}>
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: 16,
          }}
        >
          {isMobileWidth && (
            <Text
              type="sectionTitle"
              customCSS={{
                marginBottom: 32,
                paddingLeft: 32,
                paddingRight: 32,
                [MEDIA_QUERIES.xs.max]: {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
              }}
            >
              {t("about.title")}
            </Text>
          )}
          <img
            src={AboutImage}
            alt="About trustlines"
            className="lozad"
            css={{
              alignSelf: "flex-start",
              width: "100%",
              [MEDIA_QUERIES.s.max]: {
                objectFit: "scale-down",
                height: "50vh",
                width: "100vw",
              },
              [MEDIA_QUERIES.xs.max]: {
                height: "auto",
                width: "100vw",
              },
            }}
          />
        </div>
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: 16,
            [MEDIA_QUERIES.l.max]: {
              justifyContent: "flex-start",
              paddingRight: 16,
            },
            [MEDIA_QUERIES.s.max]: {
              paddingRight: 32,
              paddingLeft: 32,
            },
            [MEDIA_QUERIES.xs.max]: {
              paddingLeft: 16,
              paddingRight: 16,
            },
          }}
        >
          {!isMobileWidth && (
            <Text type="sectionTitle" customCSS={{ marginBottom: 32 }}>
              {t("about.title")}
            </Text>
          )}
          <div
            css={{
              marginBottom: 32,
              [MEDIA_QUERIES.m.max]: {
                marginTop: 32,
              },
            }}
          >
            <Text type="sectionBody">{t("about.first")}</Text>
          </div>
          <Text type="sectionBody" customCSS={{ marginBottom: 32 }}>
            <Trans i18nKey="about.second">
              The Trustlines App reflects the idea of{" "}
              <a
                className="paragraph-link"
                target="_blank noreferrer"
                href={ExternalLinks.PEOPLE_POWERED_MONEY}
              >
                people-powered
              </a>{" "}
              money where value can be created and managed by each individual
              without any intermediaries.
            </Trans>
          </Text>
          <Text type="sectionBody" customCSS={{ marginBottom: 32 }}>
            {t("about.third")}
          </Text>
          <Text type="sectionBody" customCSS={{ marginBottom: 32 }}>
            {t("about.fourth")}
          </Text>
          <Button
            href={ExternalLinks.USE_CASES}
            target="_blank noreferrer"
            centered={isSmallMobileWidth}
          >
            {t("about.useCases")}
          </Button>
        </div>
      </div>
    </>
  );
}
