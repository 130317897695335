import useWindowDimensions from "./useWindowDimensions";
import { BREAKPOINTS } from "../theme";

export default function useIsMobileWidth() {
  const { width } = useWindowDimensions();
  return width <= BREAKPOINTS[1];
}

export function useIsSmallMobileWidth() {
  const { width } = useWindowDimensions();
  return width <= BREAKPOINTS[0];
}
