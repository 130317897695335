/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";

import ImageWithSteps from "../components/ImageWithSteps";

import { ExternalLinks } from "../constants";

import SplitBill1 from "../images/split-bill-step-1.png";
import SplitBill1_2x from "../images/split-bill-step-1@2x.png";
import SplitBill1_3x from "../images/split-bill-step-1@3x.png";
import SplitBill2 from "../images/split-bill-step-2.png";
import SplitBill2_2x from "../images/split-bill-step-2@2x.png";
import SplitBill2_3x from "../images/split-bill-step-2@3x.png";
import SplitBill3 from "../images/split-bill-step-3.png";
import SplitBill3_2x from "../images/split-bill-step-3@2x.png";
import SplitBill3_3x from "../images/split-bill-step-3@3x.png";

const SPLIT_BILL_STEPS = [
  {
    title: "stepsSplitBill.firstTitle",
    body: "stepsSplitBill.firstBody",
    imgSrc: SplitBill1,
    imrSrcSet: `${SplitBill1} 1x, ${SplitBill1_2x} 2x, ${SplitBill1_3x} 3x`,
    id: "split-bill-step-1",
  },
  {
    title: "stepsSplitBill.secondTitle",
    body: "stepsSplitBill.secondBody",
    imgSrc: SplitBill2,
    imrSrcSet: `${SplitBill2} 1x, ${SplitBill2_2x} 2x, ${SplitBill2_3x} 3x`,
    id: "split-bill-step-2",
  },
  {
    title: "stepsSplitBill.thirdTitle",
    body: "stepsSplitBill.thirdBody",
    imgSrc: SplitBill3,
    imrSrcSet: `${SplitBill3} 1x, ${SplitBill3_2x} 2x, ${SplitBill3_3x} 3x`,
    id: "split-bill-step-3",
  },
];

export default function SplitBillSection() {
  const { t } = useTranslation();

  return (
    <ImageWithSteps
      steps={SPLIT_BILL_STEPS}
      scrollContainerID="split-bill"
      sectionTitle={t("stepsSplitBill.sectionTitle")}
      sectionBody={t("stepsSplitBill.sectionBody")}
      userGuideLink={ExternalLinks.USER_GUIDE_SPLIT_BILL}
      reverse
    />
  );
}
