/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Iframe from "react-iframe";

import privacyPolicyPart1Md from "../privacyPolicyPart1";
import privacyPolicyPart2Md from "../privacyPolicyPart2";
import imprintMd from "../imprint";

function ImprintAndPrivacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      css={{
        padding: 30,
        textAlign: "justify",
      }}
    >
      <ReactMarkdown source={imprintMd} />
      <ReactMarkdown source={privacyPolicyPart1Md} />
      <Iframe
        url="https://stats.trustlines.network/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=ffffff&fontColor=1f2532&fontSize=16px&fontFamily='Work%20Sans'"
        width="600px"
        height="200px"
        border="0"
      />
      <ReactMarkdown source={privacyPolicyPart2Md} />
    </div>
  );
}

export default ImprintAndPrivacy;
