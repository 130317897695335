import React from "react";
import { Helmet } from "react-helmet";
import ShareImage from "../images/share-preview.png"
export default function CustomHelmet({ title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{title}</title>
      <meta
        name="description"
        content="The Trustlines App is a payment app built on blockchain offering decentralized value creation"
      />
      <meta
        itemProp="name"
        content="Trustlines App - A payment app built on blockchain"
      />
      <meta
        itemProp="description"
        content="The Trustlines App is a payment app built on blockchain offering decentralized value creation"
      />
      <meta
        itemProp="image"
        content={ShareImage}
      />
      <meta
        name="google-site-verification"
        content="x8-rmE0S65eu07wJOtOgDFcgK2A4QD0wZR0EgcQSj9s"
      />
      <meta name="msvalidate.01" content="461226232A0CE4B34E124EE5F4510557" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={ShareImage}
      />
      <meta
        name="twitter:image"
        content={ShareImage}
      />
      <meta
        property="og:title"
        content="Trustlines App - A payment app built on blockchain"
      />
      <meta name="author" content="Trustlines App" />
      <meta property="og:locale" content="en_US" />
      <meta
        name="description"
        content="The Trustlines App is a payment app built on blockchain offering decentralized value creation"
      />
      <meta
        property="og:description"
        content="The Trustlines App is a payment app built on blockchain offering decentralized value creation"
      />
      <link rel="canonical" href="https://trustlines.app/" />
      <meta property="og:url" content="https://trustlines.app/" />
      <meta property="og:site_name" content="Trustlines App" />
      <meta
        property="og:image"
        content={ShareImage}
      />
      <meta name="twitter:card" content="summary" />
      <meta
        property="twitter:image"
        content={ShareImage}
      />
      <meta
        property="twitter:title"
        content="Trustlines App - A payment app built on blockchain"
      />
      <meta
        name="twitter:title"
        content="Trustlines App - A payment app built on blockchain"
      />
      <meta
        name="twitter:description"
        content="The Trustlines App is a payment app built on blockchain offering decentralized value creation"
      />
      <meta name="twitter:site" content="@trustlines_app" />
      <meta name="twitter:creator" content="@trustlines_app" />
      <script type="application/ld+json">
        {JSON.stringify({
          description: "Trustlines App - A payment app built on blockchain",
          "@type": "WebSite",
          headline: "Trustlines App",
          sameAs: [
            "https://twitter.com/trustlines_app",
            "https://trustlines.network/",
            "https://trustlines.foundation/",
            "https://twitter.com/TrustlinesFound",
            "https://reddit.com/r/Trustlines",
            "https://github.com/trustlines-protocol",
            "https://docs.trustlines.network/",
            "https://blog.trustlines.network/",
            "https://forum.trustlines.network/",
            "https://dev.trustlines.network/",
            "https://t.me/trustlines_network",
            "https://gitter.im/trustlines/community",
          ],
          publisher: {
            "@type": "Organization",
            logo: {
              "@type": "ImageObject",
              url: ShareImage,
            },
            name: "Trustlines App",
          },
          author: {
            "@type": "Person",
            name: "Trustlines App",
          },
          url: "https://trustlines.app/",
          name: "Trustlines App",
          image: ShareImage,
          "@context": "https://schema.org",
        })}
      </script>
    </Helmet>
  );
}
