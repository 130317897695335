/** @jsxImportSource @emotion/react */
import { useHistory } from "react-router-dom";
import Text from "../components/Text";
import { useTranslation } from "react-i18next";

import withMaxWidth from "../hoc/withMaxWidth";
import { MEDIA_QUERIES } from "../theme";
import { ExternalLinks } from "../constants";

export default function Footer() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: "flex",
        backgroundColor: "rgba(249, 249, 249, 1)",
      }}
    >
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 225,
            padding: 32,
            [MEDIA_QUERIES.m.max]: {
              paddingTop: 32,
              paddingLeft: 16,
              paddingRight: 16,
            },
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: 225,
              [MEDIA_QUERIES.m.max]: {
                flexDirection: "column",
                paddingTop: 32,
                paddingLeft: 16,
                paddingRight: 16,
              },
            }}
          >
            <Text
              type="footerLink"
              customCSS={{
                marginRight: 32,
                [MEDIA_QUERIES.m.max]: {
                  marginRight: 0,
                  marginBottom: 32,
                },
              }}
              href={ExternalLinks.FOUNDATION}
              target="_blank noreferrer"
              isAnchor
            >
              Trustlines Protocol
            </Text>
            <Text
              type="footerLink"
              customCSS={{
                marginRight: 32,
                [MEDIA_QUERIES.m.max]: {
                  marginRight: 0,
                  marginBottom: 32,
                },
              }}
              href={ExternalLinks.NETWORK}
              target="_blank noreferrer"
              isAnchor
            >
              Trustlines Network
            </Text>
            <Text
              type="footerLink"
              onClick={() => history.push("/imprint-privacy-policy")}
            >
              {t("footer.privacy")}
            </Text>
          </div>
          <FooterBoxTM>
            Trustlines®, Trustlines Network® and the Trustlines unicorn logo are
            trademarks or registered trademarks of Trustlines Foundation, in the
            EU and / or other countries.
          </FooterBoxTM>
        </div>
      )}
    </div>
  );
}

function FooterBoxTM({ title, ...props }) {
  return (
    <div
      css={{
        opacity: 0.5,
        color: "rgba(170, 170, 187, 1)",
        fontSize: 12,
        paddingTop: 45,
        textTransform: "uppercase",
        textAlign: "center",
      }}
      {...props}
    />
  );
}
