export const ExternalLinks = {
  APP_STORE: "https://apps.apple.com/us/app/trustlines-app/id1541175356",
  PLAY_STORE:
    "https://play.google.com/store/apps/details?id=network.trustlines.mobileapp",
  TRUSTLINE:
    "https://docs.trustlines.network/resources/wp_content/how_trustlines_works/#22-what-is-a-trustline",
  FOUNDATION: "https://trustlines.foundation",
  NETWORK: "https://trustlines.network",
  PEOPLE_POWERED_MONEY:
    "https://docs.trustlines.network/resources/community_communications_guide/#people-powered-money-ppm",
  NETWORK_EFFECT:
    "https://docs.trustlines.network/resources/wp_content/how_trustlines_works/#25-the-network-effect",
  USE_CASES:
    "https://docs.trustlines.network/resources/wp_content/use_in_the_real_world/",
  TWITTER: "https://twitter.com/trustlines_app",
  FORUM: "https://forum.trustlines.network/",
  TELEGRAM: "https://t.me/trustlines_network",
  FEEDBACK_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLScfixgjw1md1rq8z-dgYrXLvGbtnmP8daHIPwK_JGNvJmUQ2g/viewform?usp=sf_link",
  APK: "https://storage.googleapis.com/mobileapp-releases/app-production-release.apk",
  USER_GUIDE: "https://docs.trustlines.network/guides/tl_app_user_guide/",
  USER_GUIDE_CREATE_TRUSTLINE:
    "https://docs.trustlines.network/guides/tl_app_user_guide/#creating-a-trustline",
  USER_GUIDE_SEND_AND_REQUEST:
    "https://docs.trustlines.network/guides/tl_app_user_guide/#sending-a-payment",
  USER_GUIDE_MULTI_HOP:
    "https://docs.trustlines.network/guides/tl_app_user_guide/#sending-and-receiving-payments",
  USER_GUIDE_SPLIT_BILL:
    "https://docs.trustlines.network/guides/tl_app_user_guide/#split-request",
};
