import { useLocation } from "react-router-dom";
import { useEffect } from "react";

/**
 * Track page changes
 */
export function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    window._paq && window._paq.push(["trackPageView"]);
  }, [location]);
}
