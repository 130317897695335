import { useEffect, useState } from "react";

export default function useDeviceUserAgent() {
  const [isMobile, setMobile] = useState(false);
  const [os, setOS] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    const os = Boolean(userAgent.match(/iPhone|iPad|iPod/))
      ? "ios"
      : Boolean(userAgent.match(/Android/))
      ? "android"
      : "else";
    setMobile(mobile);
    setOS(os);
  }, []);

  return { isMobile, os };
}
