/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

import Text from "../components/Text";
import withMaxWidth from "../hoc/withMaxWidth";
import { MEDIA_QUERIES } from "../theme";
import { ExternalLinks } from "../constants";

import ArrowRightIcon from "../images/arrow-right.svg";

const circleStyle = {
  backgroundColor: "white",
  height: 16,
  width: 16,
  borderRadius: 8,
  marginRight: 8,
  ":hover": {
    cursor: "pointer",
  },
};

const ITEMS = [
  {
    backgroundColor: "#FF7C4E",
    title: "ecosystem.firstTitle",
    body: "ecosystem.firstBody",
    to: ExternalLinks.NETWORK,
    circleLabel: "ecosystem.firstCircleLabel",
  },
  {
    backgroundColor: "#09E0A3",
    title: "ecosystem.secondTitle",
    body: "ecosystem.secondBody",
    to: ExternalLinks.FOUNDATION,
    circleLabel: "ecosystem.secondCircleLabel",
  },
];

export default function EcosystemSection() {
  const { t } = useTranslation();
  const [itemIndex, setItemIndex] = useState(0);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setItemIndex(itemIndex === 0 ? 1 : 0);
    }, 10000);
    return () => clearInterval(intervalID);
  }, [setItemIndex, itemIndex]);

  const currentItem = ITEMS[itemIndex];

  return (
    <div
      id="ecosystem-section"
      css={[
        {
          display: "flex",
          backgroundColor: currentItem.backgroundColor,
          overflowX: "hidden",
        },
      ]}
    >
      {withMaxWidth(
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: 56,
            paddingBottom: 56,
            [MEDIA_QUERIES.l.max]: {
              padding: 32,
            },
            [MEDIA_QUERIES.xs.max]: {
              paddingTop: 32,
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 32,
            },
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginBottom: 32,
            }}
          >
            {ITEMS.map((item, index) => {
              return (
                <SliderCircleItem
                  key={`${item.circleLabel}`}
                  isActive={itemIndex === index}
                  label={item.circleLabel}
                  onClick={() => setItemIndex(index)}
                />
              );
            })}
          </div>
          <SwitchTransition>
            <CSSTransition
              key={currentItem.title}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="ecosystem-slide-fade"
            >
              <Text
                type="bannerTitle"
                customCSS={{
                  marginBottom: 32,
                }}
              >
                {t(currentItem.title)}
              </Text>
            </CSSTransition>
          </SwitchTransition>
          <SwitchTransition>
            <CSSTransition
              key={currentItem.body}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="ecosystem-slide-fade"
            >
              <Text
                type="bannerBody"
                customCSS={{
                  marginBottom: 32,
                }}
              >
                {t(currentItem.body)}
              </Text>
            </CSSTransition>
          </SwitchTransition>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              type="bannerBody"
              customCSS={{
                fontWeight: 600,
                color: "black",
                alignSelf: "center",
              }}
            >
              {t("ecosystem.bottom")}
            </Text>
            <div css={{ display: "flex" }}>
              <a
                css={{
                  backgroundColor: "white",
                  height: 90,
                  width: 90,
                  borderRadius: 45,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    cursor: "pointer",
                  },
                  [MEDIA_QUERIES.m.max]: {
                    height: 32,
                    width: 32,
                    borderRadius: 16,
                  },
                }}
                href={currentItem.to}
                target="_blank norefferer"
              >
                <img
                  src={ArrowRightIcon}
                  css={{
                    height: 80,
                    [MEDIA_QUERIES.m.max]: {
                      height: 24,
                    },
                  }}
                  alt="Arrow icon"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SliderCircleItem({ isActive, onClick, label }) {
  const { t } = useTranslation();

  return (
    <div
      css={[
        {
          display: "flex",
          flexDirection: "row",
          marginRight: 24,
          ":hover": {
            cursor: "pointer",
          },
          [MEDIA_QUERIES.s.max]: {
            marginRight: 16,
          },
        },
        {
          opacity: isActive ? 1 : 0.5,
        },
      ]}
      onClick={onClick}
    >
      <div css={circleStyle} />
      <div css={{ color: "white" }}>{t(label)}</div>
    </div>
  );
}
