/** @jsxImportSource @emotion/react */
import Text from "../components/Text";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";

import { MEDIA_QUERIES, BASE_FLEX_STYLE, SECTION_MARGIN_STYLE } from "../theme";
import { ExternalLinks } from "../constants";
import { useIsSmallMobileWidth } from "../hooks/useIsMobileWidth";

import CheckIcon from "../images/check.svg";
import SendPaymentImage from "../images/send-payment.svg";

const ITEMS = [
  {
    body: "send.firstBody",
  },
  {
    body: "send.secondBody",
  },
  {
    body: "send.thirdBody",
  },
];

export default function SendPaymentSection() {
  const { t } = useTranslation();
  const isSmallMobileWidth = useIsSmallMobileWidth();

  return (
    <div
      css={[
        BASE_FLEX_STYLE,
        SECTION_MARGIN_STYLE,
        {
          [MEDIA_QUERIES.s.max]: {
            flexDirection: "column-reverse",
          },
        },
      ]}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          paddingRight: 16,
          flex: 1,
          [MEDIA_QUERIES.l.max]: {
            paddingLeft: 16,
            flex: 0.95,
            paddingRight: 0,
          },
          [MEDIA_QUERIES.s.max]: {
            paddingLeft: 32,
            paddingRight: 32,
            marginTop: 32,
            flex: 1,
          },
          [MEDIA_QUERIES.xs.max]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        }}
      >
        <div css={{ marginBottom: 24 }}>
          <Text type="sectionTitle">{t("send.title")}</Text>
        </div>
        <div css={{ marginBottom: 24 }}>
          <Text type="sectionBody">{t("send.subTitle")}</Text>
        </div>
        <div css={{ marginBottom: 32 }}>
          {ITEMS.map((item, i) => (
            <ListItem text={item.body} key={`list-item-${i}`} />
          ))}
        </div>
        <Button
          href={ExternalLinks.USER_GUIDE_SEND_AND_REQUEST}
          target="_blank noreferrer"
          centered={isSmallMobileWidth}
        >
          {t("userGuide")}
        </Button>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingLeft: 16,
          paddingTop: 64,
          [MEDIA_QUERIES.l.max]: {
            paddingLeft: 32,
          },
          [MEDIA_QUERIES.s.max]: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <img
          className="lozad"
          src={SendPaymentImage}
          alt="send payment"
          css={{
            width: "100%",
            [MEDIA_QUERIES.s.max]: {
              width: "100vw",
            },
          }}
        />
      </div>
    </div>
  );
}

function ListItem({ text }) {
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 16,
      }}
    >
      <img
        src={CheckIcon}
        alt="Check icon"
        css={{
          marginRight: 16,
        }}
      />
      <Text type="sectionBody">{t(text)}</Text>
    </div>
  );
}
