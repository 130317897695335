/** @jsxImportSource @emotion/react */
import { MEDIA_QUERIES, BREAKPOINTS } from "../theme";

import PhoneImageSmall from "../images/phone-small.png";
import PhoneImage from "../images/phone.png";
import PhoneImage2x from "../images/phone@2x.png";
import PhoneImage3x from "../images/phone@3x.png";

export default function RightHero() {
  return (
    <div
      css={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        paddingLeft: 16,
        [MEDIA_QUERIES.l.max]: {
          paddingLeft: 0,
        },
        [MEDIA_QUERIES.s.max]: {
          alignSelf: "end",
          justifyContent: "flex-end",
          height: "100%",
        },
      }}
    >
      <div
        css={{
          width: "100%",
          height: 658,
          zIndex: 0,
          background:
            "linear-gradient(131.04deg, #F1B3C2 -30.65%, #7657ED 12.4%, #007AFF 71.98%, #81BCFD 114.38%)",
          transform: "rotate(180deg)",
          [MEDIA_QUERIES.l.max]: {
            maxWidth: BREAKPOINTS[3],
            width: "100%",
            height: 440,
          },
          [MEDIA_QUERIES.s.max]: {
            maxWidth: BREAKPOINTS[1],
            width: "100vw",
            height: 200,
          },
          [MEDIA_QUERIES.xs.max]: {
            maxWidth: BREAKPOINTS[0],
            height: 150,
          },
        }}
      />
      <picture css={{ display: "contents" }}>
        <source
          media="(max-width: 768px)"
          srcSet={`${PhoneImageSmall} 1x, ${PhoneImage} 2x`}
        />
        <img
          css={{
            maxWidth: "100%",
            height: "auto",
            zIndex: 1,
            position: "absolute",
            [MEDIA_QUERIES.l.max]: {
              height: 440,
            },
            [MEDIA_QUERIES.s.max]: {
              maxWidth: BREAKPOINTS[1],
              height: "auto",
              width: 567,
            },
            [MEDIA_QUERIES.xs.max]: {
              maxWidth: BREAKPOINTS[0],
              height: "auto",
              width: 350,
            },
          }}
          src={PhoneImage}
          srcSet={`${PhoneImage} 1x, ${PhoneImage2x} 2x, ${PhoneImage3x} 3x`}
          alt="Phone with trustlines app"
        />
      </picture>
    </div>
  );
}
